import React, { Component } from 'react';
import Gourdex from './components/Gourdex.js';
import './App.css';

class App extends Component {

  render() {
    return (
          <Gourdex />
    );

  }
}

export default App;
