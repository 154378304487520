import React, { Component } from 'react';
import { FormControl, InputGroup, Row } from "react-bootstrap";
import '../App.css';
import htflag from '../htflag.png';

class Gourdex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            myExchangeRate: 131.0000,
            myUsDollars: 0.00,
            myHtGourdes: 0.00,
            myHtDollars: 0.00,
        }
        this.handleChangeUsDollars = this.handleChangeUsDollars.bind(this);
        this.handleChangeHtGourdes = this.handleChangeHtGourdes.bind(this);
        this.handleChangeHtDollars = this.handleChangeHtDollars.bind(this);
        this.handleChangeExchangeRate = this.handleChangeExchangeRate.bind(this);
        this.handleClickFieldUsDollars = this.handleClickFieldUsDollars.bind(this);
    }

    handleClickFieldUsDollars(event) {
        this.setState({
            myUsDollars: 0.00,
            myHtGourdes: 0.00,
            myHtDollars: 0.00,
        });
    }
    handleClickFieldHtGourdes(event) {
        this.setState({
            myUsDollars: 0.00,
            myHtGourdes: 0.00,
            myHtDollars: 0.00,
        });
    }
    handleClickFieldHtDollars(event) {
        this.setState({
            myUsDollars: 0.00,
            myHtGourdes: 0.00,
            myHtDollars: 0.00,
        });
    }


    handleChangeExchangeRate(event) {

        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                myExchangeRate: event.target.value,
                myUsDollars: 0.00,
                myHtGourdes: 0.00,
                myHtDollars: 0.00,
            });
        }
    }

    handleChangeUsDollars(event) {
        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                myUsDollars: event.target.value,
                myHtGourdes: event.target.value * this.state.myExchangeRate,
                myHtDollars: (event.target.value * this.state.myExchangeRate) / 5,
            });
        }
    }

    handleChangeHtGourdes(event) {
        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                myUsDollars: event.target.value / this.state.myExchangeRate,
                myHtGourdes: event.target.value,
                myHtDollars: event.target.value / 5,
            });
        }
    }

    handleChangeHtDollars(event) {
        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                myUsDollars: (event.target.value * 5) / this.state.myExchangeRate,
                myHtGourdes: event.target.value * 5,
                myHtDollars: event.target.value,
            });
        }
    }

    render() {

        let myExchangeRate = this.state.myExchangeRate;
        let myUsDollars = this.state.myUsDollars.toLocaleString();
        let myHtGourdes = this.state.myHtGourdes.toLocaleString();
        let myHtDollars = this.state.myHtDollars.toLocaleString();

        return (
            <>
                <div className="bgimg">
                    <div className="container">
                        <b>GOURDEX</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img alt="gourdes" src={htflag} /><br />
                        A Three-Way Haitian Currency Converter.
                        <hr /><br />
                        <Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Exchange Rate : <br/><b>US Dollar $1.00 = </b></InputGroup.Text>
                                <FormControl
                                    htmlSize={8}
                                    aria-label="Gourdes"
                                    name={myExchangeRate}
                                    value={myExchangeRate}
                                    onChange={(e) => this.handleChangeExchangeRate(e)}
                                /> <b>HT Gourdes (HTG)</b>
                            </InputGroup>
                        </Row>
                        <hr />
                        <br />
                        <center>
                            <table border="0" >
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormControl
                                                htmlSize={12}
                                                aria-label="US Dollar (USD)"
                                                name="myUsDollars"
                                                value={myUsDollars}
                                                onChange={(e) => this.handleChangeUsDollars(e)}
                                                onClick={(e) => this.handleClickFieldUsDollars(e)}
                                            />

                                        </td>
                                        <td>
                                        <b>US Dollars (USD)</b>
                                        </td>
                                    </tr>

                                    <tr>

                                        <td>
                                            <FormControl
                                                htmlSize={12}
                                                aria-label="HT Gourde (HTG)"
                                                name="myHtGourdes"
                                                value={myHtGourdes}
                                                onChange={(e) => this.handleChangeHtGourdes(e)}
                                                onClick={(e) => this.handleClickFieldHtGourdes(e)}
                                            />
                                        </td>
                                        <td>
                                        <b>HT Gourdes (HTG)</b>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <FormControl
                                                htmlSize={12}
                                                aria-label="HT Dollar (HTD)"
                                                name="myHtDollars"
                                                value={myHtDollars}
                                                onChange={(e) => this.handleChangeHtDollars(e)}
                                                onClick={(e) => this.handleClickFieldHtDollars(e)}
                                            />
                                        </td>
                                        <td>
                                        <b>HT Dollars (HTD)</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </center>
                        <br /> <br />
                        <hr />
                        <Row>gourdex.com - Ⓒ 2022 copyright, Citadier S.A. - <a href="mailto:rafreid@yahoo.com">e-mail</a></Row>
                    </div>
                </div>

            </>
        );

    }
}

export default Gourdex;
